@import url("https://fonts.googleapis.com/css2?family=poppins:wght@400;500;700&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "poppins";
  scroll-behavior: smooth !important;
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slideInLeft {
  animation: slideInLeft 0.5s forwards;
  opacity: 0;
  /* Ensure the element is hidden before animation */
}


/* google login */

.googlelogin {
  max-width: 320px;
  display: flex;
  padding: 0.5rem 1.4rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  gap: 0.75rem;
  color: rgb(65, 63, 63);
  background-color: #fff;
  cursor: pointer;
  transition: all .6s ease;
}

.googlelogin svg {
  height: 24px;
}

.googlelogin:hover {
  transform: scale(1.02);
}

.active {
  background: #05a6f0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 25px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 18px;
}

/* hover-left offerings */
.slide-hover-left-1 {
  background-image: linear-gradient(0, #81bc06, #81bc06);
  background-size: 0;
  transition: 0.8s;
  background-repeat: no-repeat;
}

.slide-hover-left-1:hover {
  background-size: 100%;
  color: #fff;
}

/* Webkit browsers */
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

/* Firefox */
* {
  scrollbar-width: hidden;
  scrollbar-color: transparent;
}

.doublearrow-bg {
  background-image: url("./assets/icon/doublearrow.png");
}

.doublearrowend {
  background-image: url("./assets/icon/doublearrowend.svg");
  background-position: right;
  background-position-x: 90%;
}

.bg-img {
  background-image: url("./assets/icon/ourMission.png");
}

.nav-ul li {
  cursor: pointer;
}

.nav-ul li:hover {
  text-decoration: underline;
}

.registration-btn {
  background-color: #81bc06;
  width: 450px;
  height: 50px;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  margin-top: 25px;
}

.headerNavbar li {
  margin: 25px;
  font-weight: bold;
  cursor: pointer;
  height: 25px;
}

.headerNavbar li:hover {
  border-bottom: 1px solid black;
}

.exploring-item {
  width: 255px;
  height: 255px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.exploring-item:hover {
  background-color: #81bc06;

  button {
    display: block;
    margin: auto;
    cursor: pointer;
  }
}

.exploring-item img {
  margin: auto;
}

.exploring-heading {
  font-family: poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.exploring-detail {
  font-family: poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
}

.exploring-item button {
  width: 110px;
  height: 35px;
  background-color: #81bc06;
  border-radius: 10px;
  display: none;
}

.contact_form {
  background-color: #f8f8f8;
  width: 500px;
  padding: 35px;
  box-sizing: border-box;
  /* Include padding in the width calculation */
  border: 1px solid #81bc06;
  border-radius: 10px;
}

.contact_form input,
.career_form input,
.career_form select,
textarea {
  width: 430px;
  height: 25px;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 5px;
  border: 1px solid gray;
}

.contact_form button {
  background-color: #81bc06;
  width: 400px;
  height: 50px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.career_form_container {
  background-color: #81bc06;
}

.career_form {
  background-color: white;
  padding: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.career_form_fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blog_buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.blog_buttons button {
  background-color: white;
  width: Hug (266px) px;
  height: Hug (39px) px;
  padding: 10px 20px 10px 20px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #878787;
  opacity: 0px;
  font-family: poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: center;
  cursor: pointer;
}

.blog_buttons button:hover {
  background-color: #81bc06;
}

.file_input {
  margin: auto;
}

th {
  
  height: 90px;
  width: 100vw;
}

td {
  width: 150px;
}

.product-container {
  border: 2px solid #81bc06;
  border-radius: 10px;
  width: 1037px;
  padding: 50px;
  background-color: #f8f8f8;
  margin-top: 50px;
}

.blog-container {
  border: 2px solid #81bc06;
  border-radius: 10px;
  width: 1037px;
  background-color: #f8f8f8;
  margin-top: 50px;
}

.clientInput-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: white;
  width: 501px;
  height: 454px;
  border-radius: 10px;
  border: 2px solid #81bc06;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
}

.logo-container {
  display: flex;
  flex-wrap: wrap;
}

.logo-container img {
  width: 231.05px;
  height: 157.27px;
  gap: 0px;
  border-radius: 10px 0px 0px 0px;
  opacity: 0px;
}

.marquee {
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;
  will-change: transform;
}

.marquee-inner {
  display: flex;
  will-change: transform;
  gap: 50px;
  animation: marquee 10s linear  backwards infinite;
}

.marquee1 .marquee-inner {
  animation-direction: normal;
}



@keyframes marquee {
  0% {
    transform: translate(-100%);
  }

  100% {
    transform: translate(0);
  }
}

@media screen and (max-width: 5000px) and (min-width: 600px) {
  .marquee {
    max-width: 98vw;
  }
}

/* .custom-crop {
  width: 400px;
  height: 400px;
  overflow: hidden;
  object-fit: cover;
} */

/* Add this CSS to your stylesheet */

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.slide-right {
  animation: slideIn 1s forwards;
}

.slide-left {
  animation: slideOut 1s forwards;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 786px;
  opacity: 0;
  transition: opacity 1s;
}

.slide-active {
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 1s forwards;
}

/* card animation */

.catCard {
  background: #d8d8d8;
  background-position: center;
 
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
.lowerCatCard {
  position: absolute;
  padding: 5px 5px;
  height: 200px;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.catCard:hover .lowerCatCard {
  opacity: 0.95;
  bottom: 0;
  height: 390px;
}
.catCard .lowerCatCard {
  left: 0;
  bottom: -22%;
  width: 100%;
}
.catCard .cardHoverTitle {
  background-image: linear-gradient(0, #05a6f0, #05a6f0);
  background-size: 0;
  transition: 0.8s;
  background-repeat: no-repeat;
  width: max-content;
  padding-right: 20px;
}
.catCard:hover .cardHoverTitle {
  background-size: 100%;
  color: #fff;
  border-radius: 8px;
  padding-left: 5px;
}
.catCard:hover .bluebox {
  display: none;
}

.cardHoverTitleNav{
  background-image: linear-gradient(0, #05a6f0, #05a6f0);
  background-size: 0;
  border-radius: 0;
  transition: 0.8s;
  background-repeat: no-repeat;
  width: max-content;
  padding-right: 20px;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.cardHoverTitleNav:hover{
  background-size: 100%;
  color: #fff;
  padding-left: 5px;
}
.cardHoverTitleNav:hover .bluebox{
  display: none;
}

/* partners slider clip div */
.partnersClipDiv {
  z-index: 10;
  clip-path: polygon(0 0, 100% 0, 58% 100%, 0 100%);
}
/* Blogs card */

.blogCard {
  box-shadow: 0px 5px 10px 0px #00000080;
}

/* WorldMap  */

figure {
  background-color: transparent !important;
}

/* Button 
.......................................................................*/
.button {
  cursor: pointer;
  font-size: 14px !important;
  color: #ffffff;
  padding: 7px 10px !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  letter-spacing: 0 !important;
  background: #000;
  border: none;
  border-bottom: solid 1px #c90c12;
  text-align: center;
  margin-top: 10px;
}
.button:hover {
  background-color: #c90c12;
  border: none;
  border-bottom: solid 1px #000;
  box-shadow: 0 2px 3px #a8a8a8;
  transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -o-transform: scale(1.04);
  -ms-transform: scale(1.04);
}
.button:active {
  background: #000;
}
.button a {
  color: #ffffff;
  padding: 7px 30px !important;
  text-decoration: none;
}
.button a:hover {
  color: #ffffff;
}
.button:hover {
  color: #fff;
}

/* -------------------------------------History ----------------------*/

.historyCard{
  position: relative;
}

.timediv {
  font-size: 1em;
  line-height: 1.75em;
  border-top: 1px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-color: #000;
  margin: 0;
  padding: 60px;
  padding-top: 110px;
  counter-increment: section;
  position: relative;
  color: #34435E;
}

.historyCard:nth-child(odd)::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #81BC06;
  text-align: center;
  line-height: 1.25em;
  color: #ffffff;
  font-size: 1em;
  bottom: -72%;
  z-index: 1;
}
.historyCard:nth-child(even)::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #05A6F0;
  text-align: center;
  line-height: 1.25em;
  color: #ffffff;
  font-size: 1em;
  bottom: -72%;
  z-index: 1;
}


.timediv:nth-child(odd) {
  border-right: 0;
  padding-left: 0;
  border-top: 0;
  padding-left: 60px;
  position: relative;  
}


.timediv:nth-child(odd):before {
  left: 100%;
  margin-left: -20px;
}

.timediv:nth-child(even) {
  border-left: 0;
  padding-right: 0;
  border-top: 0;
  padding-right: 80px;
}

.timediv:nth-child(even):before {
  right: 100%;
  margin-right: -20px;
}

.timediv:first-child {
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  padding-top: 0;
}

.timediv:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;
}

.timediv:first-child:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20%; 
  width: 80%; 
  height: 1px;
  background-color: #000; 
}
.timediv:last-child:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 42%;
  width: 58%;
  height: 1px; 
  background-color: #000; 
}

.timediv:nth-child(even) .historyCard:first-child:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #05A6F0;
  text-align: center;
  line-height: 1.25em;
  color: #ffffff;
  font-size: 1em;
  bottom: -72%;
  z-index: 1;
}
.timediv:nth-child(even) .historyCard:last-child::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: #81BC06;
  text-align: center;
  line-height: 1.25em;
  color: #ffffff;
  font-size: 1em;
  bottom: -72%;
  z-index: 1;
}

.historyCard:nth-child(odd)::after {
  content: attr(data-year); 
  position: absolute;
  bottom: -7.8rem; 
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  color: black;
  font-weight: bold;
  z-index: 1;
}

.historyCard:nth-child(even)::after {
  content: attr(data-year); 
  position: absolute;
  bottom: -7.8rem; 
  width: max-content;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  color: black;
  font-weight: bold;
  z-index: 1;
}

/* ---------------------Media quries for histroy timeline -----------------------------*/
@media screen and (max-width:760px) {
  .lowerCatCard{
    height: 160px;
  }
}
@media screen and (max-width:600px) {
  .historyerapper{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
 
  .timediv{
    flex-direction: column;
    padding: 10px;
  }
  .historyCard{
    padding: 10px;
    width: 100%;
    height: 115px;
    font-size: 1em;
  }

  .timediv:nth-child(odd){
    padding-left: 10px;
    padding-bottom: 80px;
    gap: 80px;
  }
  .timediv:nth-child(even){
    padding-right: 10px;
    padding-bottom: 80px;
    gap: 80px;
  }
  .historyCard:nth-child(odd)::before{
    height: 20px;
    width: 20px;
    bottom: -25px;
  }
  .historyCard:nth-child(even)::before{
    height: 20px;
    width: 20px;
    bottom: -26px;
  }
  .historyCard:nth-child(odd)::after{
    bottom: -3.5rem;
    font-size: 18px;
  }
  .historyCard:nth-child(even)::after{
    bottom: -3.5rem;
    font-size: 18px;
  }
  .timediv:nth-child(even) .historyCard:first-child:before{
    width: 20px;
    height: 20px;
    bottom: -33%;
  }
  .timediv:nth-child(even) .historyCard:last-child::before{
    width: 20px;
    height: 20px;
    bottom: -25px;
  }
  .timediv:last-child .historyCard:last-child::before{
    width: 20px;
    height: 20px;
    bottom: -25px;
  }
  .timediv:last-child .historyCard:first-child::before{
    width: 20px;
    height: 20px;
    bottom: -25px;
  }
  .timediv:first-child{
    border-left: 1px solid black;
  }
  .timediv:first-child:after{
    left: 0;
    width: 100%;
  }
  .timediv:last-child:after{
    right: 0;
    width: 100%;
  }
}

.hoverChemCard:hover .hoverGridborder{
  background-color: #05A6F0;
}
.hoverChemCard:hover .hovertext{
  color: #05A6F0;
  transition: all .3s ease-in;
}
.hoverChemCard:hover .hovertextGreen{
  color: #81BC06;
  transition: all .3s ease-in;
}

@media screen and (min-width:600px) and (max-width:1024px) {
  .historyerapper{
    width: 90%;
  }
  .historyCard:nth-child(odd)::after, .historyCard:nth-child(even)::after{
    font-size: 22px;
    width: max-content;
  }
  .historyCard{
  
  font-size: 14px;
  }
  .timediv:last-child:after{
    right: 27%;
    width: 73%;
  }
  .timediv:first-child:after{
    left: 27%;
    width: 73%;
  }
}

@media screen and (min-width:1025px) and (max-width:1440px) {
  .historyerapper{
    width: 70%;
  }
  .historyCard:nth-child(odd)::after, .historyCard:nth-child(even)::after{
    font-size: 28px;
  }
  .historyCard{
  font-size: 14px;
  }
  .timediv:last-child:after{
    right: 27%;
    width: 73%;
  }
  .timediv:first-child:after{
    left: 27%;
    width: 73%;
  }
}


.path2 {
  stroke-dasharray: 2220;
/*   stroke-dashoffset: 800; */
  animation: draw1 8s linear alternate;
}

@keyframes draw1 {
  from {
     
    stroke-dashoffset: 2220;
  }
  to {
   
    stroke-dashoffset: 0;
  }
}